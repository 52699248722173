<template>
  <v-container fluid no-gutters>
    <div class="row row--dense">
      <v-col cols="12" xl="12" lg="12">
        <div class="d-flex justify-space-between flex-wrap" >
          <div class="d-flex bordered qp-tab-nav">
            <div class="nv_item">
              <v-autocomplete
                  :items="venueServices"
                  v-model="venueServiceIds"
                  item-value="venue_service_id"
                  item-text="name"
                  outlined
                  multiple
                  @change="changeVenueService"
                  placeholder="Services"
                  background-color="rgba(79, 174, 175, 0.1)"
                  hide-details
                  style="max-width: 150px;"
                  class="q-autocomplete shadow-0 nv_item_select q-tab-autocomplete"
                  :height="46"
                  dense
                  color="#4FAEAF"
                  item-color="#4FAEAF"

              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleSelect()">
                    <v-list-item-action>
                      <v-icon
                          :color="venueServiceIds.length > 0 ? 'teal darken-4' : ''"
                      >{{ getServiceIcon() }}</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template
                    v-if="venueServiceIds.length == venueServices.length"
                    v-slot:selection="{ index }"
                >
                  <span v-if="index == 0" class="text-neon">All Services</span>
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0" class="text-neon text-elepsis">{{ item.name }}</span>
                  <span v-if="index == 2" class="text-neon">, ..</span>
                </template>
              </v-autocomplete>
            </div>
            <MembershipTopTab :dashboardClass="true" :scheduleClass="false" :scheduleNewClass="false" :analyticsClass="false" :salesClass="false" :membersClass="false"/>
          </div>
          <div class="d-flex gap-x-2">
            <v-btn
                color=" darken-1"
                class="white--text blue-color ml-1"
                text
                height="48"
                v-if="checkWritePermission($modules.memberships.dashboard.slug)"
                @click="addMembership"
            >
              <AddIcon/>
              <span class="ml-1">Add Membership</span>
            </v-btn>
          </div>
        </div>
      </v-col>
    </div>
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>

    <v-row dense>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-switch
              dense
              hide-details
              :false-value="2"
              :true-value="1"
              v-model="membershipStatus"
              @change="changeVenueService"
              label="Active Members"
          ></v-switch>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="membership in membershipList" :key="membership.slug" lg="4" md="6" xl="3" sm="12">
        <membership-widget
          v-bind="membership"
          @edit="editMembership"
          @delete="deleteMembershipWarning"
        />
      </v-col>
    </v-row>
    <h3 class="text-center mt-12" v-if="membershipList.length == 0">
      No {{ membershipStatus == 1 ? "active" : "inactive" }} membership present
    </h3>
    <v-pagination
      v-if="totalPages"
      v-model="page"
      :length="totalPages"
      class="new-pagination"
    ></v-pagination>

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import MembershipWidget from "@/components/Membership/MembershipWidget";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import MembershipTopTab from "./MembershipTabs/MembershipTopTab";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
export default {
  components: {
    AddIcon,
    MembershipWidget,
    ConfirmModel,
    MembershipTopTab,
  },
  mounted() {

    this.initializeConfiguration();
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then(() => {
        this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
        );
        this.loadMemberships();
      });
    }else{
      this.venueServiceIds = this.venueServices.map(
          (item) => item.venue_service_id
      );
      this.loadMemberships();
    }

    this.venueServiceIds = this.venueServices.map(
        (item) => item.venue_service_id
    );
  },
  watch: {
    page() {
      this.loadMemberships();
    },
    venueServices(venuS , old){
      if(!old.length && venuS.length){
        this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
        );
      }
    }

  },
  data() {
    return {
      page: 1,
      membershipList: [],
      totalPages: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      btnShow: false,
      membershipStatus: 1,
      venueServiceIds: [],
    };
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter(
        (service) => service.name != "POS"
      );
    },
  },
  methods: {
    loadMemberships() {
      this.showLoader("Loading");
      let url = this.venueServiceIds
        .map((item, index) => `&venue_service_ids[${index}]=${item}`)
        .join(",");
      this.$http
        .get(
          `venues/memberships?page=${this.page}&per_page=12&status_id=${this.membershipStatus}${url}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.membershipList = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changeMembershipStatus(id) {
      this.$http
        .put(`venues/memberships/${id}/status`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.loadMemberships();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    confirmActions(data) {
      if (data.type == "delete") {
        this.changeMembershipStatus(data.id);
      }
      this.confirmModel.id = null;
    },
    deleteMembershipWarning(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${data.type} this Membership?`,
        description: `By clicking <b>Yes</b> you can confirm ${data.type} this membership.  Do you need to continue your action ?`,
        type: "delete",
      };
    },
    addMembership() {
      this.$router.push({ name: "MembershipAdd" });
    },
    editMembership(id) {
      this.$router.push({ name: "MembershipEdit", params: { id: id } });
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (this.venueServices.length == this.venueServiceIds.length) {
          this.venueServiceIds = [];
        } else {
          this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.loadMemberships();
      });
    },
    getServiceIcon() {
      if (this.venueServiceIds.length == 0) return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.venueServiceIds.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    initializeConfiguration() {
      if (!this.$store.getters.configurationStatus("Membership")) {
        this.$store.dispatch("loadConfigurations", "Membership");
      }
    },
    changeVenueService(){
      if(this.page != 1){
        this.page = 1;
      }else{
        this.loadMemberships();
      }
    }
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 85%;
  overflow: hidden;
}

.light-black-text{
  color: rgba(17, 42, 70, 0.8) !important;
}
</style>
