<template>
  <v-card class=" shadow  membership_card" style="border-radius: 8px"
          @click="viewMembership"
          :ripple="false"
  >
    <v-card-text class="relative">
      <v-menu
          absolute
          content-class="q-menu"
          right
          top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              :ripple="false"
              absolute
              class="text_capitalize options"
              elevation="0"
              right
              top
              v-bind="attrs"
              v-on="on"
              tile
          >
            <DotsIcon/>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-if="checkWritePermission($modules.memberships.dashboard.slug)"
              @click="editMembership"
          >
            <SvgIcon class="font-medium text-sm gap-x-2" text="Edit">
              <template #icon>
                <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
              </template>
            </SvgIcon>
          </v-list-item>
          <v-divider class="mb-2"/>
          <v-list-item v-if="checkWritePermission($modules.memberships.dashboard.slug) && status_id == 1"
                       @click="cancelMembership">
            <SvgIcon
                :class="{'red--text svg-stroke-red':status_id === 1}"
                :text="(status_id === 1 ? 'Deactivate': 'Activate')"
                class="font-medium text-sm gap-x-2"
            >
              <template #icon>
                <ActivateIcon/>
              </template>
            </SvgIcon>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-row class="border-bottom pb-2 align-center" dense>
        <v-col cols="5" style="">
          <simple-image
              :image="image_path"
              defaultImage="membership"
              style="border-radius: 8px ; min-height: 165px"
          ></simple-image>
        </v-col>
        <v-col cols="7">
          <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
              <p v-on="on" v-bind="attrs" class="text-lg black-text font-bold text-truncate ml-2 membership_heading" style="max-width: 80%">
                {{ name }}
              </p>
            </template>
            {{name}}
          </v-tooltip>
          <v-divider/>
          <v-card
              class="overflow-y-hidden  mt-1 shadow-0  "
              style="min-height: 130px; max-height: 130px"
          >
            <div v-if="description" class="p-1" v-html="description"></div>
            <div v-else class="p-1">
              <p>No Description found</p>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense class="mt-4">
        <v-col cols="6" class="justify-center text-center" v-if="checkReadPermission($modules.memberships.members.slug)">
          <div class="membership_stats">
            <p class="text-base text-dark-gray">Members</p>
            <p class="text-lg font-medium text-blue  ">{{ members_count | numberFormatter }}</p>
          </div>
        </v-col>
        <v-col cols="6" class="justify-center text-center" v-if="checkReadPermission($modules.memberships.sales.slug)">
          <div class="membership_stats">
            <p class="text-base text-dark-gray">Sales</p>
            <p class="text-lg font-medium text-blue  ">{{ sales | numberFormatter }}</p>
          </div>
        </v-col>
        <v-col class="justify-center text-center" cols="6">
          <div class="membership_stats">
            <p class="text-base text-dark-gray"> Packages</p>
            <p class="text-lg font-medium text-blue  ">{{ packages_count | numberFormatter }}</p>
          </div>
        </v-col>
        <v-col cols="6" class="justify-center text-center" v-if="checkReadPermission($modules.memberships.sales.slug)">
          <div class="membership_stats">
            <p class="text-base text-dark-gray">Expiring This Month</p>
            <p class="text-lg font-medium text-blue  ">{{ expiring_this_month | numberFormatter }}</p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SimpleImage from "@/components/Image/SimpleImage.vue";
import EditIcon from "@/assets/images/tables/edit.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DotsIcon from "@/assets/images/misc/h-options.svg";
import ActivateIcon from "@/assets/images/partners/activate.svg";

export default {
  components: {ActivateIcon, DotsIcon, SvgIcon, EditIcon, SimpleImage},
  props: {
    id: {type: Number, default: 0},
    name: {type: String, default: ""},
    description: {type: String, default: ""},
    sales: {type: Number, default: 0},
    members_count: {type: Number, default: 0},
    image_path: {
      type: String,
      default: null,
    },
    packages_count: {type: Number, default: 0},
    expiring_this_month: {type: Number, default: 0},
    status_id: {type: Number, default: 2},
  },

  methods: {
    editMembership() {
      this.$router.push({
        name: "MembershipEdit",
        params: {data: btoa(this.id)},
      });
    },
    viewMembership() {
      this.$router.push({
        name: "MembershipView",
        params: {data: btoa(this.id)},
      });
    },
    cancelMembership() {
      this.$emit('delete', {
        id: this.id,
        type: this.status_id == 1 ? 'Deactivate' : 'Activate',
      })
    },
  },
};
</script>

<style scoped>
.member_details .v-list-item__content {
  padding: 6px 8px;
}

.member_details .v-list-item {
  min-height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
}

.member_details .v-list-item__icon {
  margin: 2px 2px 2px 2px;
}

.detail_box {
  padding-top: 0px;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1;
}

.v-list-item__title {
  align-self: center;
  font-size: 0.9rem;
}


.membership_card {
  border: 1px solid rgba(17, 42, 70, 0);
  cursor: pointer;
}

.membership_card:hover {
  border: 1px solid rgba(17, 42, 70, 0.5);
  box-shadow: 0 8px 24px 0 rgba(70, 76, 136, 0.20) !important;

}

.membership_stats {
  p {
    margin: 0;
    padding: 0;
  }
}


@media screen and (min-width: 1264px) and (max-width: 1380px) {
  .stats_col {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .membership_heading {
    margin-bottom: 1px !important;

  }

  .v-image {
    aspect-ratio: 1/1.5 !important;
  }
}


@media screen and (min-width: 1381px) and (max-width: 1725px) {
  .membership_heading {
    margin-bottom: 1px !important;
  }

  .stats_col {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 1726px) and (max-width: 1903px) {

  .stats_col {
    margin-top: 4px !important;
    margin-bottom: 0 !important;
  }
}


@media screen and (min-width: 1904px) and (max-width: 2100px) {
  .membership_stats {
    margin-top: 1px !important;
  }

  .membership_heading {
    margin-bottom: 4px !important;
  }

  .stats_col {
    margin-top: 2px !important;
    margin-bottom: 0 !important;

  }
}


@media screen and (min-width: 2101px) and (max-width: 2300px) {

  .membership_heading {
    margin-bottom: 1px !important;
  }

  .stats_col {
    margin-top: 0 !important;
    margin-bottom: 8px !important;

  }
}

@media screen and (min-width: 2301px) and (max-width: 2450px) {
  .stats_col {
    margin-top: 0 !important;
    margin-bottom: 8px !important;
  }
}

.options {
  &,&:hover{
    background-color: transparent !important;
  }
  min-width: 26px !important;
  height: 26px !important;
  padding: 2px !important;
  top:10px !important;
}

</style>
